import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
const Welcome = lazy(() => import('@pages/welcome/welcome'));
const AuthLayout = lazy(() => import('@pages/auth/authLayout'));
const Login = lazy(() => import('@pages/auth/login/login'));
const Register = lazy(() => import('@pages/auth/register/register'));
const AppLayout = lazy(() => import('@pages/app/appLayout'));
const Home = lazy(() => import('@pages/app/home/home'));
const Map = lazy(() => import('@pages/app/map/map'));
const Profile = lazy(() => import('@pages/app/profile/profile'));
const NotFound = lazy(() => import('@pages/notFound/notFound'));

const ItineraryPage = lazy(
	() => import('@pages/app/anotherForMoment/itineraryPage'),
);

function AppRouter(): JSX.Element {
	return (
		<Routes>
			<Route path="/" element={<Welcome />} />

			<Route path="/auth" element={<AuthLayout />}>
				<Route index element={<Navigate to="login" />} />
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
			</Route>

			<Route path="/app" element={<AppLayout />}>
				<Route index element={<Navigate to="home" />} />
				<Route path="another" element={<ItineraryPage />} />
				<Route path="home" element={<Home />} />
				<Route path="map" element={<Map />} />
				<Route path="profile" element={<Profile />} />
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default AppRouter;
