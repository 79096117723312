import { createContext, useContext, useEffect, useState } from 'react';
import i18n from '@utils/i18n';

const TranslateContext = createContext<TranslateContextType | undefined>(
	undefined,
);

export function useTranslateContext(): TranslateContextType {
	const context = useContext(TranslateContext);

	if (!context) {
		throw new Error(
			'useTranslateContext must be used within a TranslateProvider',
		);
	}

	return context;
}

function TranslateProvider({ children }: AppProviderProps): JSX.Element {
	const [locale, setLocale] = useState<string>(i18n.language.split('-')[0]);
	// TODO: fix changing language after refresh
	const langs = [
		{ value: 'fr', label: 'Français' },
		{ value: 'en', label: 'English' },
		// { value: 'nl-BE', label: "Nederlands" },
		// { value: 'du-DE', label: "Deutsch" }
	];

	const handleChangeLanguage = (value: string): void => {
		i18n.changeLanguage(value.split('-')[0]);
	};

	useEffect(() => {
		i18n.on('languageChanged', () =>
			setLocale(i18n.language.split('-')[0]),
		);

		return (): void => {
			i18n.off('languageChanged', () =>
				setLocale(i18n.language.split('-')[0]),
			);
		};
	}, []);

	return (
		<TranslateContext.Provider
			value={{
				locale,
				langs,
				handleChangeLanguage,
			}}
		>
			{children}
		</TranslateContext.Provider>
	);
}

export default TranslateProvider;
