import AppProvider from '@app/providers/appProvider';
import AppRouter from '@app/routes/appRouter';

function App() {
	return (
		<AppProvider>
			<AppRouter />
		</AppProvider>
	);
}

export default App;
