import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import TranslateProvider from './translateProvider';
import Loader from '@components/loaders/loader';

function AppProvider({ children }: AppProviderProps): JSX.Element {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<TranslateProvider>{children}</TranslateProvider>
			</Suspense>
		</BrowserRouter>
	);
}

export default AppProvider;
