enum ButtonType {
	SUBMIT = 'submit',
	RESET = 'reset',
	BUTTON = 'button',
}

enum HTTPMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
	PATCH = 'PATCH',
}

enum InputType {
	// COLOR = 'color',
	// DATE = 'date',
	// DATETIME_COCAL = 'datetime-local',
	EMAIL = 'email',
	// FILE = 'file',
	// HIDDEN = 'hidden',
	// IMAGE = 'image',
	// MONTH = 'month',
	// NUMBER = 'number',
	PASSWORD = 'password',
	// RADIO = 'radio',
	// RANGE = 'range',
	// SEARCH = 'search',
	// TEL = 'tel',
	TEXT = 'text',
	// TIME = 'time',
	// URL = 'url',
	// WEEK = 'week',
}

(window as any).ButtonType = ButtonType;
(window as any).HTTPMethod = HTTPMethod;
(window as any).InputType = InputType;
